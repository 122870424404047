@font-face {
  font-family: 'Museo';
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  src: url('assets/fonts/Museo/normal_normal_normal.woff2') format('woff2'),
    url('assets/fonts/Museo/normal_normal_normal.woff') format('woff');
}
@font-face {
  font-family: 'Museo-900';
  src: url('assets/fonts/Museo-900/font.woff2') format('woff2'),
    url('assets/fonts/Museo-900/font.woff') format('woff');
}
@font-face {
  font-family: 'Museo';
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  src: url('assets/fonts/Museo/normal_normal_bold.woff2') format('woff2'),
    url('assets/fonts/Museo/normal_normal_bold.woff') format('woff');
}
@font-face {
  font-family: 'Museo-500';
  src: url('assets/fonts/Museo-500/font.woff2') format('woff2'),
    url('assets/fonts/Museo-500/font.woff') format('woff');
}
@font-face {
  font-family: 'Museo-100';
  src: url('assets/fonts/Museo-100/font.woff2') format('woff2'),
    url('assets/fonts/Museo-100/font.woff') format('woff');
}
@font-face {
  font-family: 'Museo-500Italic';
  src: url('assets/fonts/Museo-500Italic/font.woff2') format('woff2'),
    url('assets/fonts/Museo-500Italic/font.woff') format('woff');
}
@font-face {
  font-family: 'Museo';
  font-style: italic;
  font-weight: bold;
  font-stretch: normal;
  src: url('assets/fonts/Museo/italic_normal_bold.woff2') format('woff2'),
    url('assets/fonts/Museo/italic_normal_bold.woff') format('woff');
}
@font-face {
  font-family: 'Museo-900Italic';
  src: url('assets/fonts/Museo-900Italic/font.woff2') format('woff2'),
    url('assets/fonts/Museo-900Italic/font.woff') format('woff');
}
@font-face {
  font-family: 'Museo';
  font-style: italic;
  font-weight: normal;
  font-stretch: normal;
  src: url('assets/fonts/Museo/italic_normal_normal.woff2') format('woff2'),
    url('assets/fonts/Museo/italic_normal_normal.woff') format('woff');
}
@font-face {
  font-family: 'Museo-300Italic';
  src: url('assets/fonts/Museo-300Italic/font.woff2') format('woff2'),
    url('assets/fonts/Museo-300Italic/font.woff') format('woff');
}
@font-face {
  font-family: 'MuseoSans-500Italic';
  src: url('assets/fonts/MuseoSans-500Italic/font.woff2') format('woff2'),
    url('assets/fonts/MuseoSans-500Italic/font.woff') format('woff');
}
@font-face {
  font-family: 'MuseoSans-900';
  src: url('assets/fonts/MuseoSans-900/font.woff2') format('woff2'),
    url('assets/fonts/MuseoSans-900/font.woff') format('woff');
}
@font-face {
  font-family: 'MuseoSans-100Italic';
  src: url('assets/fonts/MuseoSans-100Italic/font.woff2') format('woff2'),
    url('assets/fonts/MuseoSans-100Italic/font.woff') format('woff');
}
@font-face {
  font-family: 'MuseoSans';
  font-style: italic;
  font-weight: normal;
  font-stretch: normal;
  src: url('assets/fonts/MuseoSans/italic_normal_normal.woff2') format('woff2'),
    url('assets/fonts/MuseoSans/italic_normal_normal.woff') format('woff');
}
@font-face {
  font-family: 'MuseoSans-100';
  src: url('assets/fonts/MuseoSans-100/font.woff2') format('woff2'),
    url('assets/fonts/MuseoSans-100/font.woff') format('woff');
}
@font-face {
  font-family: 'MuseoSans-500';
  src: url('assets/fonts/MuseoSans-500/font.woff2') format('woff2'),
    url('assets/fonts/MuseoSans-500/font.woff') format('woff');
}
@font-face {
  font-family: 'MuseoSans';
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  src: url('assets/fonts/MuseoSans/normal_normal_bold.woff2') format('woff2'),
    url('assets/fonts/MuseoSans/normal_normal_bold.woff') format('woff');
}
@font-face {
  font-family: 'MuseoSans-900Italic';
  src: url('assets/fonts/MuseoSans-900Italic/font.woff2') format('woff2'),
    url('assets/fonts/MuseoSans-900Italic/font.woff') format('woff');
}
@font-face {
  font-family: 'MuseoSans';
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  src: url('assets/fonts/MuseoSans/normal_normal_normal.woff2') format('woff2'),
    url('assets/fonts/MuseoSans/normal_normal_normal.woff') format('woff');
}
@font-face {
  font-family: 'MuseoSans';
  font-style: italic;
  font-weight: bold;
  font-stretch: normal;
  src: url('assets/fonts/MuseoSans/italic_normal_bold.woff2') format('woff2'),
    url('assets/fonts/MuseoSans/italic_normal_bold.woff') format('woff');
}

@font-face {
  font-family: 'Lato-Regular';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Lato/Lato-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-Black';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Lato/Lato-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-BlackItalic';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Lato/Lato-BlackItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-Bold';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Lato/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-BoldItalic';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Lato/Lato-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-Italic';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Lato/Lato-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-Light';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Lato/Lato-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-LightItalic';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Lato/Lato-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-Thin';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Lato/Lato-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato-ThinItalic';
  font-style: normal;
  font-weight: normal;
  src: url('assets/fonts/Lato/Lato-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Parisine-Plus-Std-Clair-Bold';
  src: url('assets/fonts/ParPlCl-Bol-Std/ParisinePlusStdCl-Bold.otf')
    format('truetype');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('assets/fonts/Roboto/Roboto-Regular.ttf')
    format('truetype');
}

@font-face {
  font-family: 'SFProDisplay';
  src: url('assets/fonts/SFProDisplay/SFPRODISPLAYMEDIUM.OTF')
    format('truetype');
}

* {
  margin: 0;
  padding: 0;
}
